import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/dashboard/Layout';
import DeleteService from '../../components/dashboard/Services/DeleteService';

const Services = () => {
  const [services, setServices] = useState([]);
  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error.message);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleIconChange = (e) => {
    setIcon(e.target.value);
  };

  const handleAddService = async () => {
    try {
      const response = await axios.post('https://admin.klikkompjuteri.com.mk/api/services', {
        name,
        icon,
      });

      setServices([...services, response.data]);
      setName('');
      setIcon('');
    } catch (error) {
      console.error('Error adding service:', error.message);
    }
  };

  // Function to handle deleting a service
  const handleDeleteService = async (id) => {
      const serviceToDelete = services.find((service) => service._id === id);
      if (serviceToDelete) {
        setServiceToDelete(serviceToDelete);
        setShowDeleteModal(true);
      } else {
        console.error('Service not found.');
      }
    };
  
    // Function to confirm deleting a service
    const handleConfirmDelete = async () => {
      try {
        await axios.delete(`https://admin.klikkompjuteri.com.mk/api/services/${serviceToDelete._id}`);
        const updatedServices = services.filter((service) => service._id !== serviceToDelete._id);
        setServices(updatedServices);
        setShowDeleteModal(false);
        setServiceToDelete(null);
      } catch (error) {
        console.error('Error deleting service:', error.message);
      }
    };
  
    // Function to cancel deleting a service
    const handleCloseDeleteModal= () => {
      setShowDeleteModal(false);
      setServiceToDelete(null);
    };
  

  const handleEditService = (id) => {
    // Find the service to edit
    const serviceToEdit = services.find((service) => service._id === id);

    // Set the editing state with service details
    setEditingServiceId(id);
    setName(serviceToEdit.name);
    setIcon(serviceToEdit.icon);
  };

  const handleUpdateService = async () => {
    try {
      const response = await axios.put(
        `https://admin.klikkompjuteri.com.mk/api/services/${editingServiceId}`,
        {
          name,
          icon
        }
      );

      // Update the services array with the edited service
      const updatedServices = services.map((service) =>
        service._id === editingServiceId ? response.data : service
      );

      setServices(updatedServices);
      handleCancelEdit();
    } catch (error) {
      console.error('Error updating service:', error.message);
    }
  };

  const handleCancelEdit = () => {
    // Clear the editing state
    setEditingServiceId(null);
    setName('');
    setIcon('');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (editingServiceId) {
      // If editing, call handleUpdateService
      handleUpdateService();
    } else {
      // If adding, call handleAddService
      handleAddService();
    }
  };

  const renderForm = () => {
    return (
      <div className="services">
        {editingServiceId ? <h3>Измени во</h3> : <h3>Додај во</h3>}
        <h2>Сервисен центар</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mt-3 row">
            <div className="mb-3 col-md-6">
              <label htmlFor="name" className="form-label">
                Име:
              </label>
              <input type="text" className="form-control" id="name" value={name} onChange={handleNameChange} required/>
            </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="icon" className="form-label">
                Icon (Font Awesome Class):
              </label>
              <input type="text" className="form-control" id="icon" value={icon} onChange={handleIconChange} required/>
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-spacing">
            {editingServiceId ? 'Измени услуга' : 'Додај услуга'}
          </button>
          {editingServiceId && (
            <button type="button" className="btn btn-secondary" onClick={handleCancelEdit}>
              Откажи
            </button>
          )}
        </form>
      </div>
    );
  };

  const renderServices = () => {
    return (
      <div className="services">
        <div className="service-cards">
          <div className="row">
              {services.map((service) => (
                <div className="col-3" key={service._id}>
                  <div className="service-card">
                    <i className={`fas ${service.icon} service-icon`}></i>
                    <h3 className="service-title">{service.name}</h3>
                    <div className="service-actions">
                      <button className="btn clicked" onClick={() => handleDeleteService(service._id)}>
                        <i className="fas fa-trash-alt"></i>
                      </button>
                      <button className="btn clicked" onClick={() => handleEditService(service._id)}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout mainContent={() => (
      <>
        {renderForm()}
        {renderServices()}
        {showDeleteModal && serviceToDelete && (
          <DeleteService itemToDelete={serviceToDelete} handleConfirmDelete={handleConfirmDelete} handleCloseDeleteModal={handleCloseDeleteModal}/>
        )}
      </>
    )} />
  );;
};
export default Services;
