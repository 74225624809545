import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './components/auth/Login';
import Services from './pages/Services/Services';
import Clients from './pages/Clients/Clients';
import ClientDetail from './components/dashboard/Clients/ClientDetail';
import ClientsByService from "./components/dashboard/Clients/table/ClientsByService"

const PrivateRoute = ({ element: Component }) => {
    const isAuthenticated = localStorage.getItem('token');
  
    return isAuthenticated ? (<Component />) : (<Navigate to="/" replace />);
  };

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<PrivateRoute element={Home} />} />
                <Route path="/services" element={<Services />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/client/:customerId" element={<ClientDetail />} />
                <Route path="/services/:serviceSlug/clients" element={<ClientsByService />} />
            </Routes>
    </Router>
    );
    }
export default App;

