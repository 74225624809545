import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../../dashboard/Layout';

const ClientsByService = () => {
  const { serviceSlug } = useParams();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const maxLength = 30;

  const getStatusBadgeStyle = (status) => {
    if (status === 'завршено') {
      return 'badge bg-success';
    } else if (status === 'тековно') {
      return 'badge bg-danger';
    } else {
      return 'badge bg-secondary';
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`https://admin.klikkompjuteri.com.mk/api/services/${serviceSlug}/clients`);
        setClients(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching clients:', error.message);
        setError(true);
        setLoading(false);
      }
    };

    fetchClients();
  }, [serviceSlug]);

  const renderClientsTable = () => {
    if (clients.length === 0) {
      return (
        <div>
          <h1>Не се пронајдени резултати...</h1>
        </div>
      );
    }
  
    return (
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>Име &amp; Презиме</th>
            <th>Телефонски број</th>
            <th>Датум</th>
            <th>Проблем</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client._id}>
              <td><Link className="table-link" to={`/client/${client.customerId}`}>{client.name}</Link></td>
              <td>{client.phoneNumber}</td>
              <td>{new Date(client.date).toLocaleDateString('en-GB')}</td>
              <td>{client.note.length > maxLength ? client.note.slice(0, maxLength) + "..." : client.note}</td>
              <td>
                <span className={`${getStatusBadgeStyle(client.status)}`}>
                  {client.status.toUpperCase()}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Layout mainContent={
      <div className="mt-5">
        {loading ? (
          <h2>Се вчитува...</h2>
        ) : error ? (
          <h2>Error fetching client data.</h2>
        ) : clients ? (
          <>
            {renderClientsTable()}
          </>
        ) : (
          <p>Не се пронајдени резултати</p>
        )}
      </div>
    } />
  );
}  
export default ClientsByService;