import React from 'react';
import Layout from '../../components/dashboard/Layout';
import FetchServices from '../../components/dashboard/Services/FetchServices';
import FetchClients from '../../components/dashboard/Clients/FetchClients';

const Home = () => {
  return (
    <Layout
      mainContent={
        <>
          <FetchServices />
          <FetchClients />
        </>
      }
    />
  );
};

export default Home;

