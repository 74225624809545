// ServiceCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ service }) => {
  return (
    <div className="service-card">
      <Link className="service-link" to={`/services/${service.nameSlug}/clients`}>
        <i className={`fas ${service.icon} service-icon`}></i>
        <h3 className="service-title">{service.name}</h3>
      </Link>
    </div>
  );
};
export default ServiceCard;
