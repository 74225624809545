// Services.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceCard from './ServiceCard';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
      setServices(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching services:', error.message);
    }
  };

  return (
    <div className="services">
      <h2>Сервисен центар</h2>
      <div className="service-cards">
        <div className="row">
          {loading ? (
            <h2>Loading...</h2>
          ) : (
            services.map((service) => (
              <div className="col-md-3" key={service._id}>
                <ServiceCard key={service._id} name={service.name} service={service} icon={service.icon} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default Services;
