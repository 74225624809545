import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ClientTable = ({ clients, services, handleEditClient, handleDeleteClient }) => {
  // State variables for filters
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [serviceFilter, setServiceFilter] = useState('');

  // State variables for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10; // Number of clients to show per page

  const maxLength = 30;

  const handleClearFilters = () => {
    setDateFilter('');
    setStatusFilter('');
    setServiceFilter('');
  };

  // Function to find service name
  const findServiceName = (serviceId) => {
    const service = services.find((service) => service._id === serviceId);
    return service ? service.name : 'N/A';
  };

  // Function to get status badge style
  const getStatusBadgeStyle = (status) => {
    if (status === 'завршено') {
      return 'badge bg-success';
    } else if (status === 'тековно') {
      return 'badge bg-danger';
    } else {
      return 'badge bg-secondary';
    }
  };

  return (
    <div>
        {/* Filter inputs */}
        <div className="row mb-3 mt-3">
        <div className="col-md-3">
          <select className="form-select" value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
            <option value="">Филтрирај по датум</option>
            <option value="asc">Растечки</option>
            <option value="desc">Опаѓачки</option>
          </select>
        </div>
        <div className="col-md-3">
          <select className="form-select" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">Филтрирај по статус</option>
            <option value="тековно">Тековно</option>
            <option value="завршено">Завршено</option>
          </select>
        </div>
        <div className="col-md-3">
          <select className="form-select" value={serviceFilter} onChange={(e) => setServiceFilter(e.target.value)}>
            <option value="">Филтрирај по тип на сервис</option>
            {services.map((service) => (
              <option key={service._id} value={service._id}>{service.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <button className="btn btn-secondary ms-2" onClick={handleClearFilters}>Ресетирај филтри</button>
        </div>
      </div>
      
      {/* Client Table */}
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>Име &amp; Презиме</th>
            <th>Телефонски број</th>
            <th>Тип на сервис</th>
            <th>Датум</th>
            <th>Статус</th>
            <th>Проблем</th>
            <th>Акции</th>
          </tr>
        </thead>
        <tbody>
          {/* Pagination logic */}
          {clients
            .filter((client) => statusFilter ? client.status === statusFilter : true)
            .filter((client) => serviceFilter ? client.service === serviceFilter : true)
            .sort((a, b) => {
              if (dateFilter === 'asc') {
                return new Date(a.date) - new Date(b.date);
              } else if (dateFilter === 'desc') {
                return new Date(b.date) - new Date(a.date);
              } else {
                return 0;
              }
            })
            .slice((currentPage - 1) * clientsPerPage, currentPage * clientsPerPage)
            .map((client) => (
              <tr key={client._id}>
                <td><Link className="table-link" to={`/client/${client.customerId}`}>{client.name}</Link></td>
                <td>{client.phoneNumber}</td>
                <td>{findServiceName(client.service)}</td>
                <td>{new Date(client.date).toLocaleDateString('en-GB')}</td>
                <td>
                  <span className={`${getStatusBadgeStyle(client.status)}`}>
                    {client.status.toUpperCase()}
                  </span>
                </td>
                <td>{client.note.length > maxLength ? client.note.slice(0, maxLength) + "..." : client.note}</td>
                <td>
                  <button title="Избриши" className="btn clicked" onClick={() => handleDeleteClient(client._id)}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                  <button title="Измени" className="btn clicked" onClick={() => handleEditClient(client._id)}>
                    <i className="fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination navigation */}
      <div className="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                {/* Previous button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)} aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </button>
                  </li>
                {/* Page numbers */}
                {Array.from({ length: Math.min(Math.ceil(clients.length / clientsPerPage), 7) }).map((_, index) => {
                  const pageNumber = currentPage <= 4 ? index + 1 : currentPage - 3 + index;
                  return (
                    pageNumber <= Math.ceil(clients.length / clientsPerPage) && pageNumber > 0 && (
                      <li key={index} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(pageNumber)}>
                          {pageNumber}
                        </button>
                      </li>
                    )
                  );
                })}
                {/* Next button */}
                <li className={`page-item ${currentPage === Math.ceil(clients.length / clientsPerPage) ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)} aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
          </nav>
      </div>
    </div>
  );
};
export default ClientTable;
