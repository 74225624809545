// Layout.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';

const Layout = ({ mainContent }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to fetch search results
  const fetchSearchResults = async () => {
    try {
      const response = await fetch(`https://admin.klikkompjuteri.com.mk/api/clients?search=${searchQuery}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // useEffect to trigger search when searchQuery changes
  useEffect(() => {
    const delayTimer = setTimeout(() => {
      fetchSearchResults();
    }, 300); 

    // Cleanup the timer on component unmount or when searchQuery changes
    return () => clearTimeout(delayTimer);
  }, [searchQuery]);

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  return (
    <div className="dashboard">
      <Sidebar logout={logout} />
      <div className="main-content">
        <div className="dashboard-header">
          <div className="search-input">
            <i className="fas fa-search search-icon"></i>
            <input type="text" placeholder="Пребарај..." value={searchQuery} onChange={handleSearchChange}/>
            {searchQuery && (
            <div className="search-results">
              {searchResults.length > 0 ? (
                searchResults.map((result) => (
                  <div key={result._id}>
                    {/* Make the client name a link */}
                    <p>
                      <a href={`/client/${result.customerId}`}>
                        {result.name} - {result.customerId}
                      </a>
                    </p>
                  </div>
                ))
              ) : (
                <p>Не се пронајдени клиенти...</p>
              )}
            </div>
          )}
          </div>
          <div className="profile-section">
            <img src="/assets/img/klik-logo.png" alt="Profile" className="profile-picture" />
            <button className="logout-btn btn-block clicked" onClick={logout}>
              <i className="fas fa-sign-out-alt"></i> Излез
            </button>
          </div>
        </div>
        {typeof mainContent === 'function' ? mainContent() : mainContent}
      </div>
    </div>
  );
};
export default Layout;