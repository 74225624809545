import React from 'react';
import { Link } from 'react-router-dom';

const ClientTable = ({ clients, services }) => {
  
  const maxLength = 50;

  // Function to find the service name from the service ID
  const findServiceName = (serviceId) => {
    const service = services.find((service) => service._id === serviceId);
    return service ? service.name : 'N/A';
  };

  // Filter and sort clients by status active in descending order
  const activeClients = clients.filter((client) => client.status === 'тековно');
  activeClients.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Show only the first 9 clients
  const limitedClients = activeClients.slice(0, 9);

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Име &amp; Презиме</th>
          <th>Тип на сервис</th>
          <th>Датум</th>
          <th>Проблем</th>
        </tr>
      </thead>
      <tbody>
        {limitedClients.map((client) => (
          <tr key={client._id}>
            <td><Link className="table-link" to={`/client/${client.customerId}`}>{client.name}</Link></td>
            <td>{findServiceName(client.service)}</td>
            <td>{new Date(client.date).toLocaleDateString('en-GB')}</td>
            <td>{client.note.length > maxLength ? client.note.slice(0, maxLength) + "..." : client.note}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClientTable;
