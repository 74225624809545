// Clients.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/dashboard/Layout';
import ClientTable from '../../components/dashboard/Clients/table/ClientTable';
import EditClient from '../../components/dashboard/Clients/edit/EditClient';
import DeleteClient from '../../components/dashboard/Clients/delete/DeleteClient';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);

  // Edit client modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);

  //Delete client modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchClients();
    fetchServices();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/clients');
      setClients(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching clients:', error.message);
      setError(true);
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
      setServices(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching services:', error.message);
      setError(true);
      setLoading(false);
    }
  };
  
   // Function to handle deleting a client
   const handleDeleteClient = async (id) => {
    const clientToDelete = clients.find((client) => client._id === id);
    if (clientToDelete) {
      setClientToDelete(clientToDelete);
      setShowDeleteModal(true);
    } else {
      console.error('Client not found.');
    }
  };

  // Function to confirm deleting a client
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`https://admin.klikkompjuteri.com.mk/api/clients/${clientToDelete._id}`);
      const updatedClients = clients.filter((client) => client._id !== clientToDelete._id);
      setClients(updatedClients);
      setShowDeleteModal(false);
      setClientToDelete(null);
    } catch (error) {
      console.error('Error deleting client:', error.message);
    }
  };

  // Function to cancel deleting a client
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setClientToDelete(null);
  };
  
  
  const handleEditClient = (id) => {
    const clientToEdit = clients.find((client) => client._id === id);
    if (clientToEdit) {
      setClientToEdit(clientToEdit);
      setShowEditModal(true);
    } else {
      console.error('Client not found.');
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setClientToEdit(null);
  };

  const handleUpdateClient = async (id, updatedClientData) => {
    try {
      const formattedDate = new Date(updatedClientData.date).toISOString();
      const response = await axios.put(`https://admin.klikkompjuteri.com.mk/api/clients/${id}`, {
        ...updatedClientData,
        date: formattedDate,
      });

      const updatedClients = clients.map((client) =>
        client._id === id ? response.data : client
      );

      setClients(updatedClients);
    } catch (error) {
      console.error('Error updating client:', error.message);
    }
  };

  return (
    <Layout mainContent={
      <div>
        {loading ? (
          <h2>Се вчитува...</h2>
        ) : (
          <>
            <ClientTable clients={clients} services={services} handleDeleteClient={handleDeleteClient} handleEditClient={handleEditClient} />
            {showEditModal && clientToEdit && (
              <EditClient showEditModal={showEditModal} handleCloseEditModal={handleCloseEditModal} clientToEdit={clientToEdit} services={services} handleUpdateClient={handleUpdateClient} />
            )}
            {showDeleteModal && clientToDelete && (
              <DeleteClient itemToDelete={clientToDelete} handleConfirmDelete={handleConfirmDelete} handleCloseDeleteModal={handleCloseDeleteModal} />
            )}
          </>
        )}
      </div>
    } />
  );
};
export default Clients;