import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    
    try {
        const response = await axios.post('https://admin.klikkompjuteri.com.mk/auth/login', {
          username,
          password
        }, {withCredentials: true} );
        
    
        if (response.status === 200) {
          // Login successful
        const { token  } = response.data.token;
        localStorage.setItem('token', token);
        navigate('/dashboard');
        }
      } catch (error) {
        if (error.response) {
          // Login failed
            setErrorMessage('Погрешно корисничко име или лозинка! Обидете се повторно.');
            console.error('An error occurred:', error.message);
        }
      }
  };

  return (
      <div className="my-container text-center">  
        <form onSubmit={handleSubmit} className="w-25">
          <img src="assets/logo/klik-logo.svg" alt="KlikComputers logo" width="480" height="220" />

          {errorMessage && (
            <div className={`alert alert-danger mt-3 ${isClicked ? 'clicked' : ''}`} role="alert">
              {errorMessage}
            </div>
          )}


          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="username" value={username} onChange={handleUsernameChange} required title='Корисничко име' />
            <label htmlFor="username">Корисничко име</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="password" value={password} onChange={handlePasswordChange} required title='Лозинка'/>
            <label htmlFor="password">Лозинка</label>
          </div>

    
          <button className="w-100 btn btn-lg btn-danger mt-3" type="submit">
            <i className="fas fa-sign-in-alt"></i> Влез
            </button>
          <p className="mt-5 mb-3 text-muted">&copy; 2024 ДООЕЛ КЛИК-КОМПЈУТЕРИ Ресен </p>
        </form>
      </div>
    
  );
};

export default Login;
