//Sidebar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AddClientModal from '../Clients/add/AddClient';

const Sidebar = () => {
  const location = useLocation();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  // Function to handle opening the modal
  const handleOpenClientModal = () => {
    setSelectedClient(null);
    setShowAddClientModal(true);
  };

  // Function to handle closing the modal
  const handleCloseClientModal = () => {
    setShowAddClientModal(false);
  };

  // Helper function to determine if a link is active
  const isLinkActive = (path) => {
      return location.pathname === path;
  };

return (
      <div className="sidebar">
          <div className="sidebar-logo">
              <img src="/assets/logo/klik-logo.svg" alt="Logo" />
          </div>
          <div className="sidebar-content">  
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link to="/dashboard" className={`nav-link ${isLinkActive('/dashboard') ? 'active' : ''}`}>
                  <i className="bi bi-house-door"></i> Контролна табла
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className={`nav-link ${isLinkActive('/services') ? 'active' : ''}`}>
                  <i className="bi bi-tools"></i> Сервисен центар
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/clients" className={`nav-link ${isLinkActive('/clients') ? 'active' : ''}`}>
                  <i className="bi bi-person"></i> Клиенти
                </Link>
              </li>
            </ul>
            <div className="mt-auto text-center">
              <button className="btn btn-danger btn-block col-12 clicked"  onClick={handleOpenClientModal}>+ Додај Клиент</button>
            </div>
          </div>
        {showAddClientModal && <AddClientModal handleCloseClientModal={handleCloseClientModal} selectedClient={selectedClient} />}
      </div>
    );
  };
export default Sidebar;