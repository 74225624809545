import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ActiveClients from './table/ActiveClients';

const ClientsTableWrapper = () => {
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchClients();
    fetchServices();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/clients');
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error.message);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error.message);
    }
  };

  return (
    <div className="clients">
      <h2>Тековно</h2>
      <ActiveClients clients={clients} services={services} />
    </div>
  );
};

export default ClientsTableWrapper;
