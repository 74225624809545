import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout';

const ClientDetail = () => {
  const { customerId } = useParams();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [services, setServices] = useState([]);


  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.get(`https://admin.klikkompjuteri.com.mk/api/clients/${customerId}`);
        setClient(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching client data:', error.message);
        setError(true);
        setLoading(false);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error.message);
      }
    };

    fetchClient();
    fetchServices();
  }, [customerId]);

  const getStatusBadgeStyle = (status) => {
    if (status === 'завршено') {
      return 'badge bg-success';
    } else if (status === 'тековно') {
      return 'badge bg-danger';
    } else {
      return 'badge bg-secondary';
    }
  };

  const findServiceName = (serviceId) => {
    const service = services.find((service) => service._id === serviceId);
    return service ? service.name : 'N/A';
  };

  return (
    <Layout mainContent={ 
      <div className="mt-5">
        {loading ? (
          <h2>Се вчитува...</h2>
        ) : error ? (
          <h2>Error fetching client data.</h2>
        ) : client ? (
              <div className="mb-5 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <p className="invoice-id">Шифра на клиент: <strong>{client.customerId}</strong></p>
                  </div>
                </div>
                <hr />

                  <div className="row">
                    <div className="col-md-9">
                      <ul className="list-unstyled">
                        <li className="text-bold font-user"><i className="fas fa-user"></i>  Клиент: <strong className="client-name">  {client.name}</strong></li>    
                        <li className="text-bold font-user"><i className="fas fa-phone"></i> Контакт телефонски број:  {client.phoneNumber}</li>
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <ul className="list-unstyled">
                        <li className="text-strong font-user"> <span className="fw-bold">Датум на примање: </span>{new Date(client.createdAt).toLocaleDateString('en-GB')}</li>
                        <li className="text-bold font-user"><i className="fas fa-tools"></i> Тип на сервис:{' '} <strong className="client-name">{findServiceName(client.service)}</strong></li>  
                      </ul>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <p className='text-bold font-user'>Белешка: {client.note}</p>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="d-flex justify-content-end">
                      <ul className="list-unstyled">
                        <li className="text-strong font-user">
                          <span className="me-1 fw-bold">Статус:</span>{' '}
                          <span className={`${getStatusBadgeStyle(client.status)}`}>
                            {client.status.toUpperCase()}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <hr />  
              </div>

        ) : (
          <h2>Клиентот не е пронајден!</h2>
        )}
      </div>
    } />
  );
};
export default ClientDetail;
