import React from 'react';

const DeleteConfirmationModal = ({ itemToDelete, handleConfirmDelete, handleCloseDeleteModal}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <span title="Откажи" className="modal-close" onClick={handleCloseDeleteModal}>
          <i className="fas fa-times"></i>
        </span>
        <h2>Избриши <strong>Сервисна услуга</strong> - {itemToDelete.name} </h2>
        <p>Дали сте сигурни дека сакате да ја избришете оваа сервисна услуга?</p>
        <div className="modal-buttons text-center">
          <button onClick={handleConfirmDelete} className="btn btn-danger clicked btn-block col-6 modal-btn">Да, избриши</button>
          <button onClick={handleCloseDeleteModal} className="btn btn-success clicked btn-block col-5 modal-btn btn-2">Не, откажи</button>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmationModal;
