import React, { useState } from 'react';

// Helper function to format the date
const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = dateObj.toISOString().split('T')[0];
    return formattedDate;
};

const EditClient = ({ 
    handleCloseEditModal,
    clientToEdit,
    services,
    handleUpdateClient
}) => {
    const [name, setName] = useState(clientToEdit.name);
    const [phoneNumber, setPhoneNumber] = useState(clientToEdit.phoneNumber);
    const [service, setService] = useState(clientToEdit.service);
    const [date, setDate] = useState(formatDate(clientToEdit.date));
    const [status, setStatus] = useState(clientToEdit.status);
    const [note, setNote] = useState(clientToEdit.note);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await handleUpdateClient(clientToEdit._id, {
            name,
            phoneNumber,
            service,
            date,
            status,
            note
          });
          handleCloseEditModal();
        } catch (error) {
          console.error('Error updating client:', error.message);
        }
    };

return (
  <div className="modal-overlay">
    <div className="modal-content">
        <span title="Откажи" className="modal-close" onClick={handleCloseEditModal}>
            <i className="fas fa-times"></i>
        </span>
        <h2>Измени во <strong>Клиенти</strong></h2>
        <form onSubmit={handleSubmit}>
            <div className="row mb-3">
                <div className="col-md-7">
                    <label htmlFor="name" className="form-label">Име/Презиме:</label>
                    <input type="text" className="form-control" id="name" value={name}  onChange={(e) => setName(e.target.value)} required/>
                </div>
                <div className="col-md-5">
                    <label htmlFor="phoneNumber" className="form-label">Контакт:</label>
                    <input type="text" className="form-control" id="phoneNumber" value={phoneNumber}  onChange={(e) => setPhoneNumber(e.target.value)} required/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="service" className="form-label">Тип на сервис:</label>
                    <select className="form-select" id="service" value={service}  onChange={(e) => setService(e.target.value)}>
                        <option value="" disabled>Избери сeрвис</option>
                            {services.map((service) => (
                                <option key={service._id} value={service._id}>
                                    {service.name}
                                </option>
                        ))}
                    </select>  
                </div>
                <div className="col-md-4">
                    <label htmlFor="date" className="form-label">Датум:</label>
                    <input type="date" className="form-control" id="date" value={date}  onChange={(e) => setDate(e.target.value)} required/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="status" className="form-label">Статус:</label>
                    <select className="form-select" id="status" value={status}  onChange={(e) => setStatus(e.target.value)}>
                    <option value="тековно">ТЕКОВНО</option>
                    <option value="завршено">ЗАВРШЕНО</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <label htmlFor="note" className="form-label">Белешка:</label>
                    <textarea type="text" className="form-control" id="note" value={note}  onChange={(e) => setNote(e.target.value)}></textarea>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary col-12 btn-block btn-spacing" type="submit">Измени</button>
            </div>
        </form>
    </div>
  </div>
 );
};

export default EditClient;
