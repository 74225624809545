//AddClient.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddClient = ({ handleCloseClientModal, selectedClient }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [service, setService] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('тековно');
  const [note, setNote] = useState('');

  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices();
    if (selectedClient) {
        setName(selectedClient.name);
        setPhoneNumber(selectedClient.phoneNumber);
        setService(selectedClient.service);
        setDate(new Date(selectedClient.date).toISOString().split('T')[0]);
        setStatus(selectedClient.status);
        setNote(selectedClient.note);
      }
  }, [selectedClient]);

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://admin.klikkompjuteri.com.mk/api/services');
      setServices(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching services:', error.message);
    }
  };
  
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleServiceChange = (e) => {
    setService(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Handle adding a new client or updating an existing client based on selectedClient
      if (selectedClient) {
        // Editing an existing client
        const formattedDate = new Date(date).toISOString();
        await axios.put(`https://admin.klikkompjuteri.com.mk/api/clients/${selectedClient._id}`, {
          name,
          phoneNumber,
          service,
          date: formattedDate,
          status,
          note
        });
        console.log('Client updated');
      } else {
        // Adding a new client
        const formattedDate = new Date(date).toISOString();
        await axios.post('https://admin.klikkompjuteri.com.mk/api/clients', {
          name,
          phoneNumber,
          service,
          date: formattedDate,
          status,
          note
        });
        console.log('Client added');
      }
      handleCloseClientModal(); // Close the modal
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span title="Откажи" className="modal-close" onClick={handleCloseClientModal}>
          <i className="fas fa-times"></i>
        </span>
        <h2>Додај во <strong>Клиенти</strong></h2>
          <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="mb-3 col-md-6">
                    <label htmlFor="name" className="form-label">Име &amp; Презиме:</label>
                    <input type="text" className="form-control" id="name" value={name} onChange={handleNameChange} required />
                </div>
                <div className="mb-3 col-md-6">
                    <label htmlFor="phoneNumber" className="form-label">Контакт телефонски број:</label>
                    <input type="text" className="form-control" id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} required />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label htmlFor="service" className="form-label">Тип на сервис:</label>
                  <select className="form-select" id="service" value={service} onChange={handleServiceChange}>
                    <option value="" disabled>Избери сервис</option>
                    {services.map((service) => (
                      <option key={service._id} value={service._id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="date" className="form-label">Датум:</label>
                  <input type="date" className="form-control" id="date" value={date} onChange={handleDateChange} required />
                </div>
                <div className="col-md-4">
                  <label htmlFor="status" className="form-label">Статус:</label>
                  <select className="form-select" id="status" value={status} onChange={handleStatusChange}>
                    <option value="тековно">ТЕКОВНО</option>
                    <option value="завршено">ЗАВРШЕНО</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="note" className="form-label">Белешка:</label>
                <textarea className="form-control" id="note" value={note} onChange={handleNoteChange}></textarea>
              </div>
          
            <div className="modal-footer text-center">
              <button type="submit" className="btn btn-primary btn-block col-12 btn-spacing">
                Додај нов
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default AddClient;
